import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ProjectList } from "../helpers/ProjectList";
import GitHub from "@mui/icons-material/GitHub";
import ComputerIcon from '@mui/icons-material/Computer';
// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import "../styles/ProjectDisplay.css";


function ProjectDisplay() {
  const { id } = useParams();
  const project = ProjectList[id];
  // let navigate = useNavigate();
  return (
    <div className="project">
      <div className="titleback">
        <h1> {project.name}</h1>
      {/* <button id="back" onClick={() => navigate(-1)}>
        <KeyboardDoubleArrowLeftIcon />
      </button> */}
      </div>
      <img src={project.image} alt="" />
      <p>
        <b>Skills:</b> {project.skills}
      </p>
      <div>
      {project.webpage && (
          <a href={project.webpage} target="blank" style={{ padding: "25px" }}>
            <ComputerIcon />
          </a>
        )}
        {/* <a href={project.webpage} target="blank" style={{padding: "25px"}}>
          <ComputerIcon />
        </a> */}
        <a href={project.link} target="blank" style={{padding: "25px"}}>
          <GitHub />
        </a>
      </div>
    </div>
  );
}

export default ProjectDisplay;
