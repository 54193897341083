import Project1 from '../assets/images/Project1.JPG';
import Project2 from '../assets/images/Project2.JPG';
import Project3 from '../assets/images/Project3.jfif';
import Project4 from '../assets/images/Project4.JPG';

export const ProjectList = [
    {
        name: "Food Ordering App",
        image: Project1,
        skills: "HTML, CSS, JavaScript, jQuery, JSON, and MongoDB",
        link: "https://github.com/J-Oswalt/Food-Order-App",
        webpage: "https://j-oswalt.github.io/Food-Order-App/"
    },
    {
        name: "E-Commerce App",
        image: Project2,
        skills: "HTML, CSS, JavaScript, jQuery, JSON, and MongoDB",
        link: "https://github.com/J-Oswalt/e_commerce_app",
        webpage: ""
    },
    {
        name: "NodeJS - Blog",
        image: Project3,
        skills: "Node.js, Express.js & MongoDB",
        link: "https://github.com/J-Oswalt/NodeJS---Blog",
        webpage: ""
    },
    {
        name: "Advertisement",
        image: Project4,
        skills: "HTML, CSS",
        link: "https://github.com/J-Oswalt/Advertisement",
        webpage: "https://j-oswalt.github.io/Advertisement/"
    },
]